import { useEffect, useState } from 'react';
import { authFetch } from '../utils/utils';
import { Resource } from '../utils/data-classes/Resource/Resource';
import useDataAuth from './useDataAuth';
import { AppError } from '../utils/utils';

export const useResources = () => {
  const { data, error } = useDataAuth(fetchResources);
  return { resources: data, error };
};

async function fetchResources(loginToken: string, signal: AbortSignal) {
  const response = await authFetch(loginToken, '/api/resources', {
    method: 'GET',
    signal,
  });

  const data = await response.json();
  const apiError = data.error;
  if (apiError !== undefined) throw new AppError(response.status, apiError);

  const resources: Resource[] = data.resources || [];
  return resources;
}
