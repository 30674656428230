import { useContext, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form"
import SuccessDialog from "../../../components/dialogs/SuccessDialog/SuccessDialog";
import ErrorDialog from "../../../components/errors/ErrorDialog/ErrorDialog";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import useProviders from "../../../hooks/useProviders";
import usePostNominalLetters from "../../../hooks/usePostNominalLetters";
import useSalutations from "../../../hooks/useSalutations";
import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import { DashAPIRoute } from "../../../utils/apis";
import { ROLES } from "../../../utils/consts";
import { validationRegEx } from "../../../utils/FormValidation";
import { AppError, authFetch } from "../../../utils/utils";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import IFTASelect from "../../register/IFTAElements/IFTASelect/IFTASelect";
import useSubmitReducer from "../../../hooks/useSubmitReducer";
import s from "./Invite.module.scss";
import _round from "lodash/round";
import LabelledCheckbox from "../../../components/inputs/LabelledCheckbox/LabelledCheckbox";
import LabelledNumberInput from "./LabelledNumberInput/LabelledNumberInput";
import { StringLiteral } from "typescript";
import IFTASelectStyles from "../../register/IFTAElements/IFTASelect/IFTASelect.module.scss";

interface InviteFormFieldValues {
  email: string;
  prid: number;
  roleId: number;
  affil: {
    discount: number,
    commission: number,
    affilId: string|undefined,
    linkToWebAffil: boolean,
  },
  enableSimplifiedPatientExperience: boolean;
  firstname: string;
  surname: string;
  salutation: string;
  postNominal: string;
}

const Invite = () => {
  const { state: { loginToken } } = useContext(LoginContext);
	const { providers, error: providersError } = useProviders();
	const { postNominalLetters, error: postNominalLettersError } = usePostNominalLetters();
	const { salutations, error: salutationsError } = useSalutations();

  const [selectedRole, setSelectedRole] = useState<number | null>(null);

  const form = useForm<InviteFormFieldValues>({
    defaultValues: {
      email: "",
      prid: undefined,
      roleId: undefined,
      affil: {
        discount: 0,
        commission: 0,
        affilId: "",
        linkToWebAffil: false,
      },
      enableSimplifiedPatientExperience: false,
      firstname: "",
      surname: "",
      salutation: "",
      postNominal: "",
    }
  });
  const { register, watch, setValue, handleSubmit, formState: { errors } } = form;
  
  const [state,dispatch] = useSubmitReducer(form);

  useEffect(() => {
    setValue("email",watch("email").replace(/ /g, "").toLowerCase());
  },[watch("email")]);

  const onSubmit = async (data: InviteFormFieldValues) => {
    dispatch({type: "PROCESSING"})
    try {
      if (loginToken === undefined) throw new AppError(401,"Login token is undefined");
      await sendInvite(loginToken, data);
      dispatch({type: "COMPLETE"});
    } catch (error) {
      dispatch({type: "ERROR", payload: error});
    } 
  }

  // Handle role change directly
  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10);
    // Update the form
    setValue("roleId", value, { shouldValidate: true });
    // Update our state
    setSelectedRole(isNaN(value) ? null : value);
  };

  return (
    <PreloginLayout>
      <div className={s.dashAdmin}>
        <h1>Send Invite for Sign-Up</h1>
			  <div>
			  	<p>Please complete the following form to send an invite to an admin/clinician/affiliate to sign up to the clinical dashboard. A sign-up token will be created and sent to the email provided when you click 'Send'. </p>
          <p>The email will contain a link to a customized sign-up page. If the invitee is an admin, they will need to select a clinician to be associated with. Once they complete the sign up, they can sign in and start using the dashboard immediately.</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IFTAInput id="firstname" label="First Name" type="text" reg={register("firstname", {
              required: "First name is required",
            })} />
            <FormErrorMessage errors={errors} name="firstname" />

            <IFTAInput id="surname" label="Surname" type="text" reg={register("surname", {
              required: "Surname is required",
            })} />
            <FormErrorMessage errors={errors} name="surname" />

            <IFTASelect id="select-title" label="Title (Dr, Prof...)" defaultValue="" disabled={!salutations} reg={register("salutation")}>
              <option value="">Select Title</option>
              {salutations && salutations.map((p, i) => (
                <option key={i} value={p.salutation}>
                  {p.salutation}
                </option>
              ))}
            </IFTASelect>
            <FormErrorMessage errors={errors} name="salutation" />

            <IFTASelect id="select-postNominalLetter" label="Post-Nominal Letters (MD, DO...)" defaultValue="" disabled={!postNominalLetters} reg={register("postNominal")}>
              <option value="">Select Post-Nominal Letter</option>
              {postNominalLetters && postNominalLetters.map((p, i) => (
                <option key={i} value={p.pnlCode}>
                  {p.pnlCode} ({p.pnlDescription})
                </option>
              ))}
            </IFTASelect>
            <FormErrorMessage errors={errors} name="postNominal" />

            <IFTAInput id="email" label="Email address" type="email" reg={register("email", {
              required: "Email is required",
              pattern: {
                value: validationRegEx.email,
                message: "Invalid email address",
              },
            })} />
            <FormErrorMessage errors={errors} name="email" />
            <IFTASelect id="select-providers" label="Provider" defaultValue="" disabled={!providers} reg={register("prid",{
              required: "Provider is required",
                valueAsNumber: true,
            })}>
              <option value="">Select Provider</option>
              {providers && providers.sort((a,b) => a.label < b.label ? -1 : 1).map((p,i) => <option key={i+1} value={p.prid}>{p.label}</option>)}
            </IFTASelect>
            <FormErrorMessage errors={errors} name="prid" />
            <div className={IFTASelectStyles.IFTASelect}>
              <select 
                className={IFTASelectStyles.iftaField}
                id="select-roles"
                {...register("roleId", { 
                  required: "Role is required",
                  valueAsNumber: true 
                })}
                onChange={handleRoleChange}
                defaultValue=""
              >
                <option value="">Select Role</option>
                <option value="2">Clinician</option>
                <option value="1">Admin</option>
                <option value="3">Clinical Affiliate</option>
              </select>
              <label className={IFTASelectStyles.iftaLabel} htmlFor="select-roles">Role</label>
              <label htmlFor="select-roles" className={IFTASelectStyles.arrow}>&or;</label>
            </div>
            <FormErrorMessage errors={errors} name="roleId" />
            <div className={s.labelledCheckbox}>
              <input id="patient-flow" type="checkbox" {...register("enableSimplifiedPatientExperience")}/>
              <label htmlFor="patient-flow">Enable simplified patient experience</label>
            </div>
            <FormErrorMessage errors={errors} name="enableSimplifiedPatientExperience" />
            {selectedRole === ROLES.AFFILIATE && <AffiliateDetails form={form} />}
            <button type="submit" disabled={state.processing}>{state.processing ? "Processing..." : "Send Invite"}</button>
          </form>
        </div>
      </div>
      <SuccessDialog
        isOpen={state.complete}
				onClose={() => dispatch({type: "RESET"})}
				title="Signup Email Sent Successfully"
      />
      <ErrorDialog
				error={state.error?.message ?? ""}
				onClose={() => dispatch({type: "RESET"})}
      />
    </PreloginLayout>
  )
}

const AffiliateDetails = ({form}: {form: UseFormReturn<InviteFormFieldValues,any>}) => {
  return (
    <div className={s.affiliateDetails}>
      <h4>Affiliate Details</h4>
      <div className={s.inputs}>
        <PercentageInput form={form} name="affil.commission" id="commission-input" label="Commission" />
        <FormErrorMessage errors={form.formState.errors} name="affil.commission" />
        <PercentageInput form={form} name="affil.discount" id="discount-input" label="Discount" />
        <FormErrorMessage errors={form.formState.errors} name="affil.discount" />
        <FormErrorMessage errors={form.formState.errors} name="affil.affilId" />
      </div>
    </div>
  )
}

interface PercentageInputProps {
  form: UseFormReturn<InviteFormFieldValues,any>,
  name: "affil.discount"|"affil.commission", 
  id: string, 
  label: string,
}

const PercentageInput = ({form,name,id,label}: PercentageInputProps) => {
  return <LabelledNumberInput percentage={true} id={id} label={label} reg={form.register(name,{
    valueAsNumber: true,
    min: {
      value: 0,
      message: `${label} cannot be less than 0%`,
    },
    max: {
      value: 100,
      message: `${label} cannot be greater than 100%`,
    }
  })}/>
}

const AffilIdInput = ({form}: {form: UseFormReturn<InviteFormFieldValues,any>}) => {
  return <LabelledNumberInput type="text" inputMode="numeric" id="affil-id-input" label="Affiliate ID"
    reg={form.register("affil.affilId",{
      required: "Affiliate ID is required",
      pattern: {
        value: /^\d+$/,
        message: "Affiliate ID must be a non-negative integer",
      },
      validate: {
        nonZero: (val) => (val && parseInt(val) !== 0) || "Affiliate ID cannot be zero"
      }
    })} 
  />
}

async function sendInvite(token: string, data: InviteFormFieldValues) {
  const res = await authFetch(token,DashAPIRoute.INVITE_STAFF.path, {
    method: "POST",
    body: JSON.stringify(getInviteBody(data)),
  });
  const { success, error }: { success: boolean, error: string } = await res.json();
  if (error) throw new AppError(res.status,error);
}

const getInviteBody = (data: InviteFormFieldValues): SendInviteBody | SendInviteBodyAffiliate => {
  const { email, prid, roleId, affil, enableSimplifiedPatientExperience, firstname, surname, salutation, postNominal } = data;
  const body: SendInviteBody = { email, prid, roleId, showNonRestrictedApp: !enableSimplifiedPatientExperience, firstname, surname, salutation, postNominal };
  if (roleId !== ROLES.AFFILIATE) return body;
  return { 
    ...body, 
    affil: {
      commission: affil.commission/100,
      discount: affil.discount/100,
    },
  }
}

interface SendInviteBody {
  email: string;
  prid: number;
  roleId: number;
  showNonRestrictedApp: boolean;
  firstname: string;
  surname: string;
  salutation: string;
  postNominal: string;

}

interface SendInviteBodyAffiliate extends SendInviteBody {
  affil: {
    commission: number;
    discount: number;
  }
}

export default Invite;