import { useContext, useEffect, useState } from "react";
import { AppError, authFetch } from "../utils/utils";
import useErrorState from "./useErrorState";
import { LoginContext } from "../contexts/LoginContext/LoginContext";

export interface ConnectedStaff {
  stfid: string;
  firstName: string;
  lastName: string;
  email: string;
  salutation?: string;
  link: boolean;
  postNominalLetter:string;
}

const useConnectedStaffs = () => {
  const [connectedStaffs, setConnectedStaffs] = useState<ConnectedStaff[] | undefined>();
  const [user, setUser] = useState<ConnectedStaff | undefined>();
  const [error, setError] = useErrorState();
  const { state: { loginToken } } = useContext(LoginContext);

  const fetchConnectedStaffs = async (token: string, signal?: AbortSignal) => {
    try {
      const res = await authFetch(token, "/api/get-clinicians-by-provider-link", { signal });
      const { staff, user, error } = await res.json();
      if (error) throw new AppError(res.status, error);

      setConnectedStaffs(staff.map(createConnectedStaff));
      setUser(createConnectedStaff(user));
    } catch (err) {
      if (err instanceof Error) { // Type-check error before accessing properties
        if (err.name !== "AbortError") setError(err);
      } else {
        console.error("Unknown error:", err); // Handle unexpected cases
      }
    }
  };

  useEffect(() => {
    if (!loginToken || connectedStaffs || user) return;
    
    const abortController = new AbortController();
    fetchConnectedStaffs(loginToken, abortController.signal);

    return () => abortController.abort();
  }, [loginToken]);

  //Add refresh function to allow manual fetching
  const refreshConnectedStaffs = () => {
    if (loginToken) {
      fetchConnectedStaffs(loginToken); // No signal needed for manual refresh
    }
  };

  return { connectedStaffs, user, error, refreshConnectedStaffs };
};

export function createConnectedStaff(staff: any): ConnectedStaff {
  return {
    stfid: staff.stfid?.toString() || '',
    firstName: staff.name || '',
    lastName: staff.surname || '',
    email: staff.email || '',
    salutation: staff.salutation || '',
    link: staff.link,
    postNominalLetter: staff.postnominalletter || ''
  };
};

export default useConnectedStaffs;
