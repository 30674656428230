import React, { useContext } from "react";
import { LoginContext } from "../../contexts/LoginContext/LoginContext";
import LoadingSpinner from "../loading-spinner/LoadingSpinner";
import s from "./Loading.module.scss";

const Loading = () => {
  const { state: { providerLogo } } = useContext(LoginContext);
  
  return (
    <div className={s.loadingContainer}>
      <header className={s.header}>
        <div>
          <span></span>
          <span>
            {providerLogo && (
              <img 
                className={s.logo} 
                src={providerLogo}
                alt="provider logo" 
              />
            )}
          </span>
          <span></span>
        </div>
      </header>
      <main>
        <div className={s.loadingContent}>
          <LoadingSpinner />
        </div>
      </main>
    </div>
  );
};

export default Loading; 