import { useEffect, useState } from "react"
import { AppError } from "../utils/utils";
import useErrorState from "./useErrorState";

const useSalutations = (): { salutations: ApiSalutation[] | undefined, error: AppError | undefined } => {
    const [salutations, setSalutations] = useState<ApiSalutation[] | undefined>();
    const [error, setError] = useErrorState();

    useEffect(() => {
        if (salutations) return;
        fetchSalutations().then(setSalutations).catch(setError);
    }, [salutations]);

    return { salutations, error };
}

const fetchSalutations = async (): Promise<ApiSalutation[]> => {
    const res = await fetch("/api/salutations");
    const { salutations, error }: { salutations: ApiSalutation[], error: string } = await res.json();
    if (error) throw new AppError(res.status, error);
    return salutations.map(createSalutations);
}

export interface ApiSalutation {
    salId: number;
    salutation: string;
}

export function createSalutations(salutationVal: ApiSalutation): ApiSalutation {
    return {
        salId: salutationVal.salId ?? undefined,
        salutation: salutationVal.salutation ?? undefined,
    }
}

export default useSalutations;