import React, { useState, useMemo, useContext } from 'react';
import { useResources } from '../../hooks/useResources';
import { Resource } from '../../utils/data-classes/Resource/Resource';
import DashLayout from '../../layouts/dash/DashLayout';
import { FiSearch } from "react-icons/fi";
import { IoIosDocument, IoIosVideocam } from "react-icons/io";
import styles from './Resources.module.scss';
import { LoginContext } from '../../contexts/LoginContext/LoginContext';
import { authFetch } from '../../utils/utils';
import VideoModal from '../../components/VideoModal/VideoModal';

type CategoryData = [string, Resource[]];

export default function Resources() {
  const { resources, error } = useResources();
  const [searchTerm, setSearchTerm] = useState('');
  const { state: { loginToken } } = useContext(LoginContext);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState({ url: '', title: '' });

  const formattedData: CategoryData[] = Object.entries(resources || {}) as unknown as CategoryData[];

  const filteredData = useMemo(() => {
    if (!searchTerm) return formattedData;

    return formattedData
      .map(([category, items]) => [
        category,
        items.filter(item => 
          item.resourceName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      ] as CategoryData)
      .filter(([_, items]) => items.length > 0);
  }, [formattedData, searchTerm]);

  const handleResourceClick = async (e: React.MouseEvent<HTMLAnchorElement>, item: Resource) => {
    if (item.resType === 'FILE') {
      e.preventDefault();
      
      try {
        const response = await authFetch(loginToken!, `/api/download-resource/${item.resId}`, {
          method: 'GET',
        });
        
        if (!response.ok) {
          throw new Error('Failed to download file');
        }
        
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = item.resourceName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Failed to download file. Please try again later.');
      }
    } else if (item.resType === 'LINK' && item.link.includes('vimeo.com')) {
      e.preventDefault();
      
      // Vimeo links are now directly in the database
      setCurrentVideo({
        url: `${item.link}?badge=0&autopause=0&player_id=0&app_id=58479&fullscreen=true`,
        title: item.resourceName
      });
      setVideoModalOpen(true);
    }
  };

  // Function to determine which icon to show
  const getResourceIcon = (item: Resource) => {
    if (item.resType === 'FILE') {
      return <IoIosDocument className={styles.resourceIcon} />;
    } else if (item.resType === 'LINK' && item.link.includes('vimeo.com')) {
      return <IoIosVideocam className={styles.resourceIcon} />;
    }
    return null;
  };

  if (error) {
    return <p style={{ color: 'red' }}>Error: {error.message}</p>;
  } 

  return (
    <DashLayout>
      <div className={styles.container}>
        <h1>Resource library</h1>
        <div className={styles.introSection}>
          <p className={styles.description}>
            This page contains helpful clinician and patient guidance materials.<br />
            Click on the links to download the documents or view the videos.
          </p>
          <div className={styles.descriptionDivider} />
        </div>

        <div className={styles.searchBar}>
          <div className={styles.inputWrapper}>
            <span className={styles.iconWrapper}><FiSearch /></span>
            <input
              type="text"
              placeholder="Search resources..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        
        {filteredData.length > 0 ? (
          <div className={styles.resourcesGrid}>
            {filteredData.map(([category, items], index) => (
              <div key={`category-${category}`} className={styles.categorySection}>
                <div className={styles.categoryName}>{category}</div>
                <div className={styles.resourcesList}>
                  <ul>
                    {items.map((item) => (
                      <li key={`resource-${item.resId}`}>
                        <a 
                          href={item.resType === 'FILE' ? '#' : item.link} 
                          onClick={(e) => {
                            if (item.resType === 'FILE' || (item.resType === 'LINK' && item.link.includes('vimeo.com'))) {
                              handleResourceClick(e, item);
                            }
                          }}
                          target={item.resType === 'LINK' && !item.link.includes('vimeo.com') ? "_blank" : "_self"} 
                          rel={item.resType === 'LINK' && !item.link.includes('vimeo.com') ? "noopener noreferrer" : ""}
                          className={styles.resourceLink}
                        >
                          {getResourceIcon(item)}
                          <span className={styles.resourceText}>{item.resourceName}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                {index < filteredData.length - 1 && <div className={styles.divider} />}
              </div>
            ))}
          </div>
        ) : (
          <p>Fetching resources...</p>
        )}
      </div>

      <VideoModal
        isOpen={videoModalOpen}
        onClose={() => setVideoModalOpen(false)}
        videoUrl={currentVideo.url}
        title={currentVideo.title}
      />
    </DashLayout>
  );
}
