import React, { useContext, useEffect, Dispatch, SetStateAction, useState } from "react";
import s from "./AddPatient.module.scss";
import { By } from "./By";
import { LoginContext } from "../../../contexts/LoginContext/LoginContext";
import HorizontalInputSelector from "../../../components/menus/HorizontalInputSelector/HorizontalInputSelector";
import { Path } from "react-hook-form";
import { validationRegEx } from "../../../utils/FormValidation";
import { CountryCode } from "libphonenumber-js/types";
import FormErrorMessage from "../../../components/errors/FormErrorMessage/FormErrorMessage";
import { AddPatientContext, AddPatientStage } from "../../../contexts/AddPatientContext/AddPatientContext";
import { LanguageSelectWidget } from "../../../components/menus/LanguageSelect/LanguageSelect";
import IFTAInput from "../../register/IFTAElements/IFTAInput/IFTAInput";
import IFTAPhoneInput from "../../register/IFTAElements/IFTAPhoneInput/IFTAPhoneInput";
import { registrationFormOptions, AddPatientFormFieldValues } from "./AddPatient";
import { useAddPatientFormContext } from "./add-patient-util/useAddPatientFormContext";
import IFTASelect from "../../register/IFTAElements/IFTASelect/IFTASelect";
import { validatePatientPhoneNumber, getIdtMailFromIdentifier } from "./add-patient-util/add-patient-util";
import useStaffRole from "./add-patient-util/useStaffRole";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { SearchField } from "../../../components/SearchField/SearchField";
import { AddNewClinicianModal } from "./AddNewClinicianModal";
import { ConnectedStaff } from "../../../hooks/useConnectedStaffs";
import useConnectedStaffs from "../../../hooks/useConnectedStaffs";
import { Button } from "../../../components/buttons/Button/Button";

export interface ReferringClinicianData {
  stfid?: string;  // Make it optional since it might not exist for new clinicians
  salutation: string;
  name: string;
  surname: string;
  link: boolean;
  postNominalLetter: string;
}

interface AddPatientDetailsProps {
  setPromptChallenge: Dispatch<SetStateAction<boolean>>;
  providerPreferences: ProviderPreference[];
  promptChallenge: boolean;
}

export default function AddPatientDetails({
  setPromptChallenge,
  providerPreferences,
  promptChallenge
}: AddPatientDetailsProps) {
  const { state: { by }, dispatch } = useContext(AddPatientContext);

  return (
    <>
      <h1>ADD NEW PATIENT</h1>
      <p className={s.explanation}>
        You may register a patient in one of two ways:
        <br />
        <br />
        <b>For simplicity</b>, you can register them with their email address and we will send them the next steps.
        <br />
        <br />
        <b>For maximum patient privacy</b>, you can enter an identifier (such as a patient number) and we will send you
        the patient instructions, which you must then forward to the patient.
      </p>
      <HorizontalInputSelector
        options={registrationFormOptions}
        selectedOption={by}
        setSelectedOption={(by: By) => dispatch({ type: "SET_BY", payload: by })} />
      <div className={s.newUser}>
        {by === "email" && (
          <ByEmailFields
            setPromptChallenge={setPromptChallenge}
            providerPreferences={providerPreferences}
            promptChallenge={promptChallenge}
          />
        )}
        {by === "identifier" && <ByIdtFields
          setPromptChallenge={setPromptChallenge}
          providerPreferences={providerPreferences}
          promptChallenge={promptChallenge}
        />}
      </div>
    </>
  );
}

const ByEmailFields = ({ setPromptChallenge, providerPreferences, promptChallenge }: {
  setPromptChallenge: Dispatch<SetStateAction<boolean>>;
  providerPreferences: ProviderPreference[];
  promptChallenge: boolean;
}) => {
  const { state: { provider, staff } } = useContext(LoginContext);
  const form = useAddPatientFormContext();
  const role = useStaffRole();
  // When user is clinician set default referring clinician to logged in user
  useEffect(() => {
    if (!staff) return;
    if (role === "clinician" || role === 'affiliate') {
      form.setValue("referringClinician", {
        stfid: staff.stfid.toString(),
        salutation: staff.salutation || "",
        name: staff.name,
        surname: staff.surname,
      });
    }
  }, [staff, role]);
  const {
    formState: { errors },
    register,
    control,
    watch,
  } = form;
  return (
    <>
      <IFTAInput
        id="firstName"
        label="First Name"
        reg={register("firstName", { required: "First name is required" })}
      />
      <FormErrorMessage errors={errors} name="firstName" />
      <IFTAInput id="lastName" label="Last Name" reg={register("lastName", { required: "Last name is required" })} />
      <FormErrorMessage errors={errors} name="lastName" />
      <IFTAInput
        id="email"
        label="Email Address"
        type="email"
        reg={register("email", {
          required: "Email address is required",
          pattern: {
            value: validationRegEx.email,
            message: "Invalid email address",
          },
        })}
      />
      <FormErrorMessage errors={errors} name="email" />
      <IFTAInput type="date" id="dateOfBirth" label="Date of Birth" reg={register("dateOfBirth")} />
      <FormErrorMessage errors={errors} name="dateOfBirth" />
      {provider?.hasPhoneAccess && provider.countryCode && (
        <PhoneInput countryCode={provider.countryCode as CountryCode} />
      )}

      <RefferringClinicianInputs />

      <div className={s.deviceOptions}>
        <h3>Device
          <Tooltip
            text="You can provide the device to the patient or order it and FoodMarble will send it to the patient. A device cannot be ordered from the dashboard once the patient is added."
            position="top"
            className="device-tooltip"
          />
        </h3>

        <div className={s.radioGroup}>
          <div className={s.radioOption}>
            <input
              type="radio"
              id="order-device"
              value="order"
              {...register("deviceOption")}
            />
            <label htmlFor="order-device">FoodMarble to mail a device to the patient</label>
          </div>
          <div className={s.radioOption}>
            <input
              type="radio"
              id="provide-device"
              value="provide"
              {...register("deviceOption")}
            />
            <label htmlFor="provide-device">
              I'll provide the device in office or patient already has a device
            </label>
          </div>
        </div>
      </div>

      <div className={s.challengeCheckbox}>
        <input
          type="checkbox"
          id="prompt-challenge"
          defaultChecked
          onChange={(e) => setPromptChallenge(e.target.checked)}
        />
        <label htmlFor="prompt-challenge">Schedule patient's first test now</label>
      </div>

      <FormErrorMessage errors={errors} name="lang" />

      <div className={s.languageSelect}>
        <LanguageSelectWidget control={control} selected={watch("lang")} name="lang" />
      </div>

      <ByEmailNextButton
        providerPreferences={providerPreferences}
      />
    </>
  );
};

const ByEmailNextButton = ({
  providerPreferences,
}: {
  providerPreferences: ProviderPreference[];
}) => {
  const { state: { processing }, dispatch } = useContext(AddPatientContext);
  const { trigger, watch, getValues, setError, clearErrors, formState: { errors } } = useAddPatientFormContext();
  const { provider } = useContext(LoginContext).state;
  const form = useAddPatientFormContext();
  const role = useStaffRole();
  const deviceOption = watch("deviceOption");

  const defaultOrderPref = providerPreferences?.find(pref => pref.ppId === 13);
  const defaultToOrder = defaultOrderPref?.val !== "0";

  const isValid = async () => {
    const fields: Path<AddPatientFormFieldValues>[] = ["email", "firstName", "lastName", "dateOfBirth"];
    if (provider?.hasPhoneAccess) fields.push("phone");
    return await trigger(fields);
  };

  const onClick = async () => {
    if (!(await isValid())) return;
    const referringClinician = getValues("referringClinician");
    if (!referringClinician?.stfid) {
      //Set error message inside the referringClinician field
      setError("referringClinician", {
        type: "manual",
        message: "A referring clinician is required.",
      });
      return;
    } else {
      //Clear the error if a valid referringClinician is selected
      clearErrors("referringClinician");
    }

    if (role === "admin") dispatch({ type: "SET_STAGE", payload: "LINK_CLINICIANS" });
    if (deviceOption === "order") {
      form.setValue("device", true);
      dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE" });
    } else {
      dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE_PROMPT" });
    }
  };

  // If default is to order (ppId 13 is "1" or null)
  if (defaultToOrder) {
    // Use onClick button for both options
    return (
      <button
        className={s.btnSecondary}
        type="button"
        disabled={processing}
        onClick={onClick}
      >
        Next
      </button>
    );
  }

  // If default is to provide (ppId 13 is "0")
  return deviceOption === "order" ? (
    // FoodMarble to mail option selected - use onClick for ORDER_DEVICE
    <button
      className={s.btnSecondary}
      type="button"
      disabled={processing}
      onClick={onClick}
    >
      Next
    </button>
  ) : (
    // Provide in office option selected - use submit
    <button
      className={s.btnSecondary}
      type="submit"
      disabled={processing}
    >
      {processing ? "Processing..." : "Next"}
    </button>
  );
};

const ByIdtFields = ({ setPromptChallenge, providerPreferences, promptChallenge }: {
  setPromptChallenge: Dispatch<SetStateAction<boolean>>;
  providerPreferences: ProviderPreference[];
  promptChallenge: boolean;
}) => {
  const { state: { provider, staff } } = useContext(LoginContext);
  const form = useAddPatientFormContext();
  const role = useStaffRole();
  // When user is clinician set default referring clinician to logged in user
  useEffect(() => {
    if (!staff) return;
    if (role === "clinician") {
      form.setValue("referringClinician", {
        stfid: staff.stfid.toString(),
        salutation: staff.salutation || "",
        name: staff.name,
        surname: staff.surname,
      });
    }
  }, [staff, role]);
  const {
    formState: { errors },
    register,
    control,
    watch,
  } = form;
  return (
    <div className={s.patientDetailsIdt}>
      <InputIdt />
      <RefferringClinicianInputs />

      <div className={s.deviceOptions}>
        <h3>Device
          <Tooltip
            text="You can provide the device to the patient or order it and FoodMarble will send it to the patient. A device cannot be ordered from the dashboard once the patient is added."
            position="top"
            className="device-tooltip"
          />
        </h3>

        <div className={s.radioGroup}>
          <div className={s.radioOption}>
            <input
              type="radio"
              id="order-device"
              value="order"
              {...register("deviceOption")}
            />
            <label htmlFor="order-device">FoodMarble to mail a device to the patient</label>
          </div>
          <div className={s.radioOption}>
            <input
              type="radio"
              id="provide-device"
              value="provide"
              {...register("deviceOption")}
            />
            <label htmlFor="provide-device">
              I'll provide the device in office or patient already has a device
            </label>
          </div>
        </div>
      </div>

      <div className={s.challengeCheckbox}>
        <input
          type="checkbox"
          id="prompt-challenge"
          defaultChecked
          onChange={(e) => setPromptChallenge(e.target.checked)}
        />
        <label htmlFor="prompt-challenge">Schedule patient's first test now</label>
      </div>

      <FormErrorMessage errors={errors} name="lang" />

      <div className={s.languageSelect}>
        <LanguageSelectWidget control={control} selected={watch("lang")} name="lang" />
      </div>
      <div className={s.nextButtonWrapper}>
        <ByIdtNextButton providerPreferences={providerPreferences} />
      </div>
    </div>
  );
};

const ByIdtNextButton = (
  {
    providerPreferences,
  }: {
    providerPreferences: ProviderPreference[];
  }
) => {
  const { state: { processing }, dispatch } = useContext(AddPatientContext);
  const { trigger, watch, getValues, setError, clearErrors, formState: { errors } } = useAddPatientFormContext();
  const { provider } = useContext(LoginContext).state;
  const form = useAddPatientFormContext();
  const role = useStaffRole();
  const deviceOption = watch("deviceOption");

  const defaultOrderPref = providerPreferences?.find(pref => pref.ppId === 13);
  const defaultToOrder = defaultOrderPref?.val !== "0";

  const isValid = async () => {
    const fields: Path<AddPatientFormFieldValues>[] = ["identifier"];
    return await trigger(fields);
  };

  const onClick = async () => {
    if (!(await isValid())) return;
    const referringClinician = getValues("referringClinician");
    if (!referringClinician?.stfid) {
      //Set error message inside the referringClinician field
      setError("referringClinician", {
        type: "manual",
        message: "A referring clinician is required.",
      });
      return;
    } else {
      //Clear the error if a valid referringClinician is selected
      clearErrors("referringClinician");
    }

    if (role === "admin") dispatch({ type: "SET_STAGE", payload: "LINK_CLINICIANS" });
    if (deviceOption === "order") {
      form.setValue("device", true);
      dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE" });
    } else {
      dispatch({ type: "SET_STAGE", payload: "ORDER_DEVICE_PROMPT" });
    }
  };

  // If default is to order (ppId 13 is "1" or null)
  if (defaultToOrder) {
    // Use onClick button for both options
    return (
      <button
        className={s.btnSecondary}
        type="button"
        disabled={processing}
        onClick={onClick}
      >
        Next
      </button>
    );
  }

  // If default is to provide (ppId 13 is "0")
  return deviceOption === "order" ? (
    // FoodMarble to mail option selected - use onClick for ORDER_DEVICE
    <button
      className={s.btnSecondary}
      type="button"
      disabled={processing}
      onClick={onClick}
    >
      Next
    </button>
  ) : (
    // Provide in office option selected - use submit
    <button
      className={s.btnSecondary}
      type="submit"
      disabled={processing}
    >
      {processing ? "Processing..." : "Next"}
    </button>
  );
};

const PhoneInput = ({ countryCode }: { countryCode: CountryCode }) => {
  const form = useAddPatientFormContext();
  return (
    <>
      <IFTAPhoneInput
        form={form}
        name="phone"
        id="phone"
        label="Phone Number"
        style={{ width: "100%" }}
        defaultCountry={countryCode}
        rules={{
          validate: (phoneNumber) => validatePatientPhoneNumber(phoneNumber?.toString() || ""),
        }}
      />
      <FormErrorMessage errors={form.formState.errors} name="phone" />
    </>
  );
};

const InputIdt = () => {
  const {
    state: { provider },
  } = useContext(LoginContext);
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useAddPatientFormContext();

  const identifier = watch("identifier");

  useEffect(() => {
    if (!identifier) return;
    setValue("identifier", identifier.toLowerCase());
  }, [identifier]);

  if (!provider) return <></>;

  return (
    <>
      <IFTAInput
        type="text"
        id="identifier"
        label="Anonymous Identifier"
        reg={register("identifier", {
          required: "Anonymous identifier is required",
          pattern: {
            value: validationRegEx.emailPrefix,
            message:
              "Identifier must be a valid email prefix. Allowed characters: letters, numbers, and special characters '.', '_', '%', '+', and '-'",
          },
          maxLength: {
            value: 64,
            message: "Identifier must have fewr than 65 characters",
          },
          validate: (val) =>
            !!getIdtMailFromIdentifier(val, provider).match(validationRegEx.email) || "Invalid email address",
        })}
      />
      <div className={s.idtMail}>
        <p style={{ whiteSpace: "pre" }}>Email: {`${getIdtMailFromIdentifier(watch("identifier"), provider)}`}</p>
      </div>
      <FormErrorMessage errors={errors} name="identifier" />
    </>
  );
};

function RefferringClinicianInputs() {
  const { setValue, getValues, watch, formState: { errors } } = useAddPatientFormContext();
  const { connectedStaffs, refreshConnectedStaffs } = useConnectedStaffs();
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const searchItems = connectedStaffs?.map(staff => ({
    label: [
      staff.salutation,
      staff.firstName,
      staff.lastName,
      staff.postNominalLetter ? ` (${staff.postNominalLetter})` : ''
    ].filter(Boolean).join(' '),
    value: staff,
    isBold: staff.link,
  })) || [];

  const referringClinician = watch("referringClinician"); // Watch for changes
  // When user is clinician set default referring clinician to logged in user
  useEffect(() => {
    if (referringClinician?.name) {
      setSearchValue(`${referringClinician.salutation} ${referringClinician.name} ${referringClinician.surname}`);
    }
  }, [referringClinician]);

  const handleStaffSelect = (staff: ConnectedStaff) => {
    const referringClinician: ReferringClinicianData = {
      stfid: staff.stfid,
      salutation: staff.salutation || '',
      name: staff.firstName,
      surname: staff.lastName,
      link: staff.link,
      postNominalLetter: staff.postNominalLetter
    };

    // Get the current list of stfids
    const currentStfids = getValues('stfids') || [];

    // Ensure that stfid is a number before adding
    const stfidAsNumber = Number(staff.stfid);

    if (!isNaN(stfidAsNumber)) {
      setValue('stfids', [...currentStfids, stfidAsNumber]);
    } else {
      console.error('Invalid stfid:', staff.stfid);
    }

    setValue('referringClinician', referringClinician);
  };



  const handleAddNew = () => {
    setShowAddNewModal(true);
    setIsSearchOpen(false);
  };

  return (
    <div className={s.referringClinicianSection}>
      <h3>Referring Clinician
        <Tooltip
          text="You can type here to search for a clinician, or select from the list. If the selected option is wrong, simply delete the text to see the full list again"
          position="top"
          className="device-tooltip"
      /></h3>

      <SearchField
        items={searchItems}
        placeholder="Type to search for a referring clinician..."
        onSelect={handleStaffSelect}
        onAddNew={handleAddNew}
        addNewText="Add new referring clinician"
        size="small"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isOpen={isSearchOpen}
        setIsOpen={setIsSearchOpen}
      />
      {errors.referringClinician && (
        <FormErrorMessage errors={errors} name="referringClinician" />
      )}

      {showAddNewModal && (
        <AddNewClinicianModal
          onClose={() => setShowAddNewModal(false)}
          onSave={(newClinician: ReferringClinicianData) => {
            handleStaffSelect({
              stfid: newClinician.stfid || '',
              firstName: newClinician.name,
              lastName: newClinician.surname,
              email: '',
              salutation: newClinician.salutation,
              link: newClinician.link,
              postNominalLetter: newClinician.postNominalLetter
            });
            const newClinicianLabel = [
              newClinician.salutation,
              newClinician.name,
              newClinician.surname
            ].filter(Boolean).join(' ');
            refreshConnectedStaffs();
            setSearchValue(newClinicianLabel);
            setShowAddNewModal(false);
          }}
        />
      )}
    </div>
  );
}

interface ProviderPreference {
  ppValId: number;
  ppId: number;
  prid: number;
  set_on: string;
  val: string;
}