import React, { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import { Button } from '../../../../../components/buttons/Button/Button';
import { HiOutlinePlus, HiMiniXMark } from "react-icons/hi2";
import { IoIosArrowDown } from 'react-icons/io';
import styles from './PreferenceDetails.module.scss';
import useProviderPreferenceKeys from '../../../../../hooks/useProviderPreferenceKeys';

const PreferenceDetails: React.FC = () => {
  const { setValue, watch } = useFormContext();
  const [selectedPreferences, setSelectedPreferences] = useState<ProviderPreference[]>([]);
  const watchPreferences = watch('preferences');
  const { preferenceKeys } = useProviderPreferenceKeys();

  const preferences: Preference[] = useMemo(() => {
    if (!preferenceKeys) return [];
    
    return preferenceKeys
      .filter(key => key.setViaDashAdmin === true)
      .map(key => {
        let options;
        // For boolean type (dataType: 0), set the standard Yes/No options
        if (key.dataType === 0) {
          options = [
            { label: "No", value: "0" },
            { label: "Yes", value: "1" }
          ];
        } else if (key.answerFormat === "Integer - day of the month") {
          options = Array.from({length: 31}, (_, i) => ({ 
            label: String(i + 1), 
            value: String(i + 1) 
          }));
        } else if (key.answerFormat === "Integer, 0 = Bill in arrears, 1 = Patient Self-pay") {
          options = [
            { label: "Bill in arrears", value: "0" },
            { label: "Patient Self-pay", value: "1" }
          ];
        }
  
        let answerFormat = key.answerFormat;
        if (key.dataType === 0) {
          answerFormat = "Boolean, 0 = false, 1 = true";
        } else if (key.answerFormat === "Integer - day of the month") {
          answerFormat = key.answerFormat;
        } else if (key.dataType === 1 && key.answerFormat.includes("SAML")) {
          answerFormat = "Integer, 1 = password, 2 = SAML, 3 = Magic Link";
        } else if (key.answerFormat === "Integer, 0 = Bill in arrears, 1 = Patient Self-pay") {
          answerFormat = "Integer, 0 = Bill in arrears, 1 = Patient Self-pay";
        }
  
        return {
          ppId: key.ppId,
          text: key.text,
          answerFormat,
          options: options || [],
          defaultValue: key.defaultValue?.toString() || "0"
        };
      })
      .sort((a, b) => a.ppId - b.ppId);
  }, [preferenceKeys]);

  useEffect(() => {
    if (watchPreferences && watchPreferences.length > 0) {
      setSelectedPreferences(watchPreferences);
    }
  }, [watchPreferences]);

  const availablePreferences = preferences.filter(
    pref => !selectedPreferences.some(pp => pp.ppId === pref.ppId)
  );

  const handleAddPreference = (selectedPreference: string) => {
    const [ppId, text] = selectedPreference.split(' - ');
    const newPreference = preferences.find(p => p.ppId === parseInt(ppId));
    if (newPreference) {
      const newPrefVal: ProviderPreference = {
        ppId: newPreference.ppId,
        val: newPreference.defaultValue,
      };
      const updatedPreferences = [...selectedPreferences, newPrefVal];
      setSelectedPreferences(updatedPreferences);
      setValue('preferences', updatedPreferences);
    }
  };

  const handlePreferenceChange = (ppId: number, value: string) => {
    const updatedPreferences = selectedPreferences.map(pp =>
      pp.ppId === ppId ? { ...pp, val: value } : pp
    );
    setSelectedPreferences(updatedPreferences);
    setValue('preferences', updatedPreferences);
  };

  const handleRemovePreference = (ppId: number) => {
    const updatedPreferences = selectedPreferences.filter(pp => pp.ppId !== ppId);
    setSelectedPreferences(updatedPreferences);
    setValue('preferences', updatedPreferences);
  };

  const handleAddPreferenceClick = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <h2>Preference Details</h2>
      <div className={styles.content}>
        <div className={styles.addPreference}>
          <Dropdown
            menu={{
              items: availablePreferences.map(p => `${p.ppId} - ${p.text}`),
              onSelect: handleAddPreference,
            }}
          >
            <Button 
              variant="outline" 
              className={styles.addPreferenceButton}
              onClick={handleAddPreferenceClick}
              disabled={availablePreferences.length === 0}
            >
              <HiOutlinePlus />
              Add a Preference
            </Button>
          </Dropdown>
        </div>
        <div className={styles.preferenceList}>
          {selectedPreferences.length === 0 ? "Start by adding a preference." : ""}
          {selectedPreferences.map(pp => {
            const pref = preferences.find(p => p.ppId === pp.ppId);
            return (
              <div key={pp.ppId} className={styles.preferenceItem}>
                <div className={styles.preferenceInfo}>
                  <span className={styles.preferenceId}>{pp.ppId}</span>
                  <span className={styles.preferenceName}>{pref?.text}</span>
                </div>
                <div className={styles.preferenceActions}>
                  <Dropdown
                    menu={{
                      items: pref?.options.map(opt => opt.label) || [],
                      onSelect: (label) => {
                        const option = pref?.options.find(opt => opt.label === label);
                        if (option) {
                          handlePreferenceChange(pp.ppId, option.value);
                        }
                      },
                    }}
                    size="small"
                  >
                    <div className={styles.preferenceValue}>
                      {pref?.options.find(opt => opt.value === pp.val)?.label || pp.val}
                      <span className={styles.iconWrapper}>
                        <IoIosArrowDown />
                      </span>
                    </div>
                  </Dropdown>
                  <Button
                    variant="ghost"
                    onClick={() => handleRemovePreference(pp.ppId)}
                    aria-label="Remove preference"
                    className={styles.removeButton}
                  >
                    <HiMiniXMark />
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PreferenceDetails;

interface Preference {
  ppId: number;
  text: string;
  answerFormat: string;
  options: { label: string; value: string }[];
  defaultValue: string;
}

interface ProviderPreference {
  ppId: number;
  val: string;
}
