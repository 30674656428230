import React, { useContext, useEffect, useState } from "react";
import { Image } from "@react-pdf/renderer";
import SvgComponent from "./SvgComponent";
import { Provider } from "../utils/data-classes/Provider";
import FileReaderPromises from "../utils/FileReaderPromise";
import { LoginContext } from "../contexts/LoginContext/LoginContext";

export interface PdfLogoSrc {
  imgSrc: string;
  svgXmlString: string | undefined;
}

export interface ProviderLogoProps {
  provider: Provider;
  logoSrc: PdfLogoSrc;
}

const ProviderLogo = ({ provider, logoSrc }: ProviderLogoProps): JSX.Element => {
  const logoHeight = 50; // Adjust height as needed

  if (isPng(logoSrc)) {
    return <Image src={logoSrc.imgSrc} style={{ height: logoHeight }} />;
  }

  if (isSvg(logoSrc)) {
    // Inject height into the SVG string
    const modifiedSvg = logoSrc.svgXmlString?.replace(
      /<svg /, 
      `<svg height="${logoHeight}" `
    );

    return <SvgComponent svgXmlString={modifiedSvg} />;
  }

  return <></>;
};



const getSvgString = async (dataUrl: string): Promise<string> => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  const result = (await new FileReaderPromises().readAsText(blob)).toString();
  return result;
};

export const fetchPdfLogoSrc = async (providerLogo: string): Promise<PdfLogoSrc> => {
  if (providerLogo.includes("data:image/svg+xml;")) {
    const svgXmlString = await getSvgString(providerLogo);
    return {
      imgSrc: providerLogo,
      svgXmlString,
    };
  }
  return {
    imgSrc: providerLogo,
    svgXmlString: undefined,
  };
};

export function usePdfLogoSrc(): PdfLogoSrc | undefined {
  const [result, setResult] = useState<PdfLogoSrc>();
  const {
    state: { providerLogo },
  } = useContext(LoginContext);
  useEffect(() => {
    if (!providerLogo) return;
    fetchPdfLogoSrc(providerLogo).then(setResult);
  }, [providerLogo]);
  return result;
}

/**
 * @param {PdfLogoSrc} logoSrc
 */
const isPng = ({ imgSrc }: PdfLogoSrc): boolean => {
  return imgSrc.includes("data:image/png;") || (imgSrc.includes("foodmarble") && imgSrc.includes(".png"));
};

/**
 * @param {PdfLogoSrc} logoSrc
 */
const isSvg = ({ imgSrc, svgXmlString }: PdfLogoSrc): boolean =>
  !!svgXmlString && imgSrc.includes("data:image/svg+xml;");

export default ProviderLogo;
