import React, { useState, useEffect, useRef } from 'react';
import { IoIosArrowDown, IoMdAdd } from 'react-icons/io';
import styles from './SearchField.module.scss';

interface SearchItem<T = any> {
  label: string;
  value: T;
  isBold?: boolean; // Optional property to indicate if the item label should be bold
}

interface SearchFieldProps<T = any> {
  items: SearchItem<T>[];
  placeholder?: string;
  onSelect?: (value: T) => void;
  onAddNew?: () => void;
  addNewText?: string;
  size?: 'default' | 'small';
  position?: 'default' | 'bottom-right';
  searchValue: string;
  setSearchValue: (value: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export const SearchField = <T,>({
  items,
  placeholder = 'Search...',
  onSelect,
  onAddNew,
  addNewText = 'Add new item',
  size = 'default',
  position = 'default',
  searchValue,
  setSearchValue,
  isOpen,
  setIsOpen
}: SearchFieldProps<T>) => {
  const [filteredItems, setFilteredItems] = useState<SearchItem<T>[]>(items);
  const searchFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchFieldRef.current && !searchFieldRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setIsOpen]);

  useEffect(() => {
    const filtered = items.filter(item =>
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchValue, items]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setIsOpen(true);
  };

  const handleSelect = (item: SearchItem<T>) => {
    setSearchValue(item.label);
    setIsOpen(false);
    if (onSelect) {
      onSelect(item.value);
    }
  };

  const handleAddNew = () => {
    setIsOpen(false);
    if (onAddNew) {
      onAddNew();
    }
  };

  return (
    <div ref={searchFieldRef} className={styles.searchField}>
      <div className={styles.inputWrapper}>
        <input
          type="text"
          value={searchValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder={placeholder}
          className={styles.input}
        />
        <span className={styles.iconWrapper}>
          <IoIosArrowDown />
        </span>
      </div>

      {isOpen && (
        <div className={`${styles.menu} ${styles[`menu--${size}`]} ${styles[`menu--${position}`]}`}>
          {filteredItems.map((item, index) => (
            <div
              key={index}
              className={`${styles.item} ${styles[`item--${size}`]} ${item.isBold ? styles.bold : ''}`}
              onClick={() => handleSelect(item)}
            >
              {item.label}
            </div>
          ))}

          <div
            className={`${styles.addNewItem} ${styles[`addNewItem--${size}`]}`}
            onClick={handleAddNew}
          >
            <span className={styles.iconWrapper}>
              <IoMdAdd />
            </span>
            {addNewText}
          </div>
        </div>
      )}
    </div>
  );
};
