import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import s from "./IFTASelect.module.scss";

type IFTASelectProps<TFieldName extends string> = Omit<React.HTMLProps<HTMLSelectElement>, "id" | "label" | "ref"> & {
  id: string;
  label: string;
  reg: UseFormRegisterReturn<TFieldName>;
  value?: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export default function IFTASelect<TFieldName extends string>({id,label,reg,value,defaultValue,onChange,...rest}: IFTASelectProps<TFieldName>) {
  return (
    <div className={s.IFTASelect}>
      <select className={s.iftaField} id={id} {...reg} value={value} defaultValue={defaultValue} onChange={onChange}{...rest}/>
      <label className={s.iftaLabel} htmlFor={id}>{label}</label>
      <label htmlFor={id} className={s.arrow}>&or;</label>
    </div>
  );
}