import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '../../../../../components/inputs/Input/Input';
import { Dropdown } from '../../../../../components/dropdown/Dropdown';
import useCountries from '../../../../../hooks/useCountries';
import useOrgs from '../../../../../hooks/useOrgs';
import useSiteCategories from '../../../../../hooks/useSiteCategories';
import { IoIosArrowDown } from 'react-icons/io';
import styles from './SiteDetails.module.scss';
import { LoginContext } from "../../../../../contexts/LoginContext/LoginContext";
import { AppError, authFetch } from "../../../../../utils/utils";

const SiteDetails: React.FC = () => {
  const { control, setValue, watch } = useFormContext();
  const { countries } = useCountries();
  const { orgs } = useOrgs();
  const { siteCategories, uniqueSiteCategories } = useSiteCategories('single');

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<string | null>(null);
  const [selectedSiteCategory, setSelectedSiteCategory] = useState<string | null>(null);
  const watchCountry = watch('provider.country');
  const watchOrgs = watch('provider.orgId');
  const watchSiteCategory = watch('provider.siteCatId');
  const { state: { loginToken, provider, staff } } = useContext(LoginContext);

  useEffect(() => {
    if (countries && watchCountry) {
      const matchingCountry = countries.find(c => c.country === watchCountry);
      if (matchingCountry) {
        setSelectedCountry(matchingCountry.label);
        setValue('provider.currency', matchingCountry.currency);
      }
    }
  }, [watchCountry, countries, setValue]);


  useEffect(() => {
    if (watchOrgs) {
      const matchingOrg = orgs?.find(o => o.orgId === watchOrgs);
      if (matchingOrg) {
        setSelectedOrg(matchingOrg.label);
      }
      // Auto-set site category if match found
      const matchingSiteCategory = siteCategories?.find(cat => cat.orgId === watchOrgs);
      if (matchingSiteCategory) {
        setValue('provider.siteCatId', matchingSiteCategory.siteCatId, { shouldValidate: true });
        setSelectedSiteCategory(matchingSiteCategory.label);
      } else {
        setSelectedSiteCategory(null);
      }
    } else {
      setSelectedOrg(null);
    }
  }, [watchOrgs, orgs, siteCategories, setValue]);

  useEffect(() => {
    if (siteCategories && watchSiteCategory) {
      const matchingSiteCategory = siteCategories.find(o => o.siteCatId === watchSiteCategory);
      if (matchingSiteCategory) {
        setSelectedSiteCategory(matchingSiteCategory.label);
      }
    }
  }, [watchSiteCategory, siteCategories]);


  const handleSiteCategorySelect = (label: string) => {
    if (label === 'Select Site Category') {
      setSelectedSiteCategory(null); // Reset selection
      setValue('provider.siteCatId', null, { shouldValidate: true }); // Set to null in form
    } else {
      setSelectedSiteCategory(label);
      const selectedSiteCategoryData = uniqueSiteCategories?.find(o => o.label === label);
      if (selectedSiteCategoryData) {
        setValue('provider.siteCatId', selectedSiteCategoryData.siteCatId, { shouldValidate: true });
      }
    }
  };



  const handleCountrySelect = (label: string) => {
    setSelectedCountry(label);
    const selectedCountryData = countries?.find(c => c.label === label);
    if (selectedCountryData) {
      setValue('provider.country', selectedCountryData.country, { shouldValidate: true });
      setValue('provider.currency', selectedCountryData.currency);
    }
  };

  const handleOrgSelect = (label: string) => {
    if (label === 'Select Organization') {
      setSelectedOrg(null); // Reset selection
      setValue('provider.orgId', null, { shouldValidate: true }); // Set to null in form
    } else {
      setSelectedOrg(label);
      const selectedOrgData = orgs?.find(o => o.label === label);
      if (selectedOrgData) {
        setValue('provider.orgId', selectedOrgData.orgId, { shouldValidate: true });
      }
    }
  };


  return (
    <div className={styles.container}>
      <h2>Site Details</h2>
      <div className={styles.content}>
        <Input
          name="provider.label"
          label="Site Label"
          placeholder='Site Label (e.g. GI Alliance Texas)'
          control={control}
          rules={{ required: 'Provider name is required' }}
          required
        />
        <Input
          name="provider.description"
          label="Description"
          placeholder='For additional context (e.g. "GIA site in Texas. Key contact MD Adam Alias")'
          control={control}
          rules={{ required: 'Description is required' }}
          required
        />
        <div className={styles.dropdownWrapper}>
          <label htmlFor="provider.orgId" className={styles.label}>Organization</label>
          <Dropdown
            menu={{
              items: [
                'Select Organization', // Default option
                ...orgs?.map(org => org.label) || []
              ],
              onSelect: handleOrgSelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedOrg || 'Select Organization'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>

        <div className={styles.dropdownWrapper}>
          <label htmlFor="provider.siteCatId" className={styles.label}>Site Category</label>
          <Dropdown
            menu={{
              items: [
                'Select Site Category', // Default option
                ...uniqueSiteCategories?.map(siteCategory => siteCategory.label) || []
              ],
              onSelect: handleSiteCategorySelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedSiteCategory || 'Select Site Category'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>

        <div className={styles.dropdownWrapper}>
          <label htmlFor="provider.country" className={styles.label}>Country *</label>
          <Dropdown
            menu={{
              items: countries?.map(country => country.label).filter(c => c !== selectedCountry) || [],
              onSelect: handleCountrySelect,
            }}
            size="small"
          >
            <div className={styles.preferenceValue}>
              {selectedCountry || 'Select Country'}
              <span className={styles.iconWrapper}>
                <IoIosArrowDown />
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SiteDetails;
