import useDataAuth from './useDataAuth';
import { authFetch } from '../utils/utils';
import { AppError } from '../utils/utils';

export interface FAQ {
    faqId: number;
    faqQuestion: string;
    faqAnswer: string;
    category: string;
    faqCatId: number;
    isActive: boolean;
}

export const useFaqs = () => {
    const { data, error } = useDataAuth(fetchFaqs);
    return { faqs: data, error };
};

async function fetchFaqs(loginToken: string, signal: AbortSignal) {
    const response = await authFetch(loginToken, '/api/faqs', {
        method: 'GET',
        signal,
    });

    const data = await response.json();
    const apiError = data.error;
    if (apiError !== undefined) throw new AppError(response.status, apiError);

    const faqs: FAQ[] = data.faqs || [];
    return faqs;
}
