import { useEffect, useState } from "react"
import { AppError } from "../utils/utils";
import useErrorState from "./useErrorState";

const usePostNominalLetters = (): { postNominalLetters: ApiPostNominalLetter[] | undefined, error: AppError | undefined } => {
    const [postNominalLetters, setPostNominalLetters] = useState<ApiPostNominalLetter[] | undefined>();
    const [error, setError] = useErrorState();

    useEffect(() => {
        if (postNominalLetters) return;
        fetchPostNominalLetters().then(setPostNominalLetters).catch(setError);
    }, [postNominalLetters]);

    return { postNominalLetters, error };
}

const fetchPostNominalLetters = async (): Promise<ApiPostNominalLetter[]> => {
    const res = await fetch("/api/post-nominal-letters");
    const { postNominalLetters, error }: { postNominalLetters: ApiPostNominalLetter[], error: string } = await res.json();
    if (error) throw new AppError(res.status, error);
    return postNominalLetters.map(createPostNominalLetters);
}

export interface ApiPostNominalLetter {
    pnlId: number,
    pnlCode: string;
    pnlDescription: string;
}

export function createPostNominalLetters(postNominalLetter: ApiPostNominalLetter): ApiPostNominalLetter {
    return {
        pnlId: postNominalLetter.pnlId,
        pnlCode: postNominalLetter.pnlCode ?? undefined,
        pnlDescription: postNominalLetter.pnlDescription ?? undefined,
    }
}

export default usePostNominalLetters;