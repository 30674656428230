import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom"
import OrderContext, { OrderContextProvider } from "../../contexts/OrderContext/OrderContext";
import { Sku } from "../../hooks/useSkus";
import PreloginLayout from "../../layouts/prelogin/PreloginLayout";
import { RegistrationContext, RegistrationContextProvider } from "../../contexts/RegisterContext/RegisterContext"
import s from "./Register.module.scss";
import RegisterForm from "./RegisterForm";
import Success from "./Success";
import OrderForms from "./OrderForms";
import ClinicalOrderStatus from "./ClinicalOrderStatus";
import { AppError, devLog, logCdAction } from "../../utils/utils";
import useTranslation from "../../hooks/useTranslation";
import LoadingSpinner from "../../components/loading-spinner/LoadingSpinner";
import { CdAction } from "../../utils/CdActions";
import BeforeYouRegister from "./BeforeYouRegister/BeforeYouRegister";

/* Wrapper components supplying contexts to contained components */

const Register = () => {
  const { patientCode }: { patientCode: string } = useParams();
  return (
    <PreloginLayout>
      <RegistrationContextProvider patientCode={patientCode}>
        <RegFormsContainer />
      </RegistrationContextProvider>
    </PreloginLayout>
  )
}

const RegFormsContainer = () => {
  const { state: { staff, error } } = useContext(RegistrationContext);
  return (
    <div className={s.register}>
      { error && <RegisterError error={error}/>}
      { !error && !staff && <LoadingSpinner />}
      { !error && staff && (
        <OrderContextProvider staff={staff}>
          <RegForms />
        </OrderContextProvider>
      )}
    </div>
  );
}

/* Forms */

const RegForms = () => {
  const { state: { patient, isRegistered, type, orderInfo, error: regError, hasViewedConsentForm, requiresConsentForm } } = useContext(RegistrationContext);
  const order = orderInfo?.order;
  const orderStatus = orderInfo?.orderStatus;
  const { state: orderContextState, dispatch: orderDispatch } = useContext(OrderContext);
  const { orderComplete, error: orderError } = orderContextState;

  const error = regError || orderError || undefined;

  useEffect(() => {
    if (!order || !orderStatus) return;
    orderDispatch({type: "SET_ORDER_COMPLETE", payload: orderComplete || isOrderComplete(order,orderStatus)});
  },[orderStatus]);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (!i18n || !patient) return;
    i18n.changeLanguage(patient.defaultLanguage);
  },[i18n,patient]);
  // Log visit to registration page
  useEffect(() => {
    if (patient && isRegistered === false) logCdAction(undefined,CdAction.VISIT_REGISTRATION_PAGE,patient.ptid.toString());
  },[patient,isRegistered]);

  // Log access of self-pay form
  useEffect(() => {
    if (patient && isRegistered && (order || !orderComplete)) logCdAction(undefined,CdAction.ACCESS_SELF_PAY_FORM,patient.ptid.toString());
  },[patient,isRegistered,order,orderComplete]);

  /**
   * Dispatch a sku to orderContext to obtain relevant pricing info
   */
  useEffect(() => {
    if (!orderInfo?.sku || orderContextState.order.sku) return;
    fetchSku(orderInfo.sku.skuId)
      .then(sku => orderDispatch({ type: "SET_SKU", payload: sku }))
      .catch((error) => {
        const appError = error instanceof AppError ? error : new AppError(400,error.message);
        orderDispatch({ type: "SET_ERROR", payload: appError});
      });
  },[orderContextState.order.sku]);

  if (!patient) return <LoadingSpinner />;

  if (isRegistered && (!order || orderComplete)) return <Success patient={patient} />

  return (
    <div className={s.register}>
      { error && <RegisterError error={error} />}
      { !error && (<>
        { !isRegistered && (<>
          {requiresConsentForm && !hasViewedConsentForm && <BeforeYouRegister />}
          {(!requiresConsentForm || hasViewedConsentForm) && <RegisterForm />}
        </>)}
        { isRegistered && <OrderForms />}
      </>)}
    </div>
  )
}

const RegisterError = ({error}: {error: AppError}) => (
  <div>
    <h2>Error {error.code}</h2>
    <p>{error.message}</p>
  </div>
)

const isOrderComplete = (order: { stripeIntent: string }, orderStatus: ClinicalOrderStatus): boolean => {
  if (order.stripeIntent) return true;
  return orderStatus === ClinicalOrderStatus.PAYMENT_SUCCEEDED || orderStatus === ClinicalOrderStatus.READY_FOR_FULFILLMENT;
}

const fetchSku = async (skuId: number) => {
  devLog(`Fetching sku for skuId=${skuId}`);
  const res = await fetch(`/api/skus/${skuId}`);
  const { skus, error } = await res.json();
  if (error) throw new AppError(res.status,error);
  return skus[0] as Sku;
}

export default Register;