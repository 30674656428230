import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useSiteCategories(stype: string) {
  const { data: siteCategories, error } = useDataAuth((token, signal) =>
    fetchSiteCategories(token, signal, stype)
  );

  const uniqueSiteCategories = siteCategories
    ? getUniqueSiteCategories(siteCategories)
    : [];

  return { siteCategories, uniqueSiteCategories, error };
}

async function fetchSiteCategories(token: string, signal: AbortSignal, stype: string): Promise<SiteCategories[]> {
  const res = await authFetch(token, `/api/get-site-categories/${stype}`, { signal });
  const { siteCategories, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error as string);
  return createSiteCategories(siteCategories);
}

function createSiteCategories(siteCategories: SiteCategories[]): SiteCategories[] {
  return siteCategories.map(siteCategory => ({
    siteCatId: siteCategory.siteCatId,
    label: siteCategory.label,
    description: siteCategory.description,
    orgId: siteCategory.orgId
  }));
}

function getUniqueSiteCategories(categories: SiteCategories[]): UniqueSiteCategories[] {
  const seen = new Set<string>();
  const result: UniqueSiteCategories[] = [];

  for (const { siteCatId, label, description } of categories) {
    const key = `${siteCatId}|${label}|${description}`;
    if (!seen.has(key)) {
      seen.add(key);
      result.push({ siteCatId, label, description });
    }
  }
  return result;
}


interface SiteCategories {
    siteCatId: number;
  label: string;
  description: string;
  orgId: number
}

// Only unique version
interface UniqueSiteCategories {
  siteCatId: number;
  label: string;
  description: string;
}
