import { AppError, authFetch } from "../utils/utils";
import useDataAuth from "./useDataAuth";

export default function useOrgs() {
  const { data: orgs, error } = useDataAuth(fetchOrgs);
  return { orgs, error };
}

async function fetchOrgs(token: string, signal: AbortSignal): Promise<Orgs[]> {
  const res = await authFetch(token, "/api/get-orgs", { signal });
  const { orgs, error } = await res.json();
  if (error !== undefined) throw new AppError(res.status, error as string);
  return createOrgs(orgs);
}

function createOrgs(orgs: Orgs[]): Orgs[] {
  return orgs.map(org => ({
    orgId: org.orgId,
    label: org.label,
    description: org.description
  }));
}

interface Orgs {
  orgId: number;
  label: string;
  description: string;
}
