import React, { useState, useMemo } from 'react';
import { useFaqs } from '../../hooks/useFaqs';
import DashLayout from '../../layouts/dash/DashLayout';
import { FiSearch, FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from './Faqs.module.scss';

interface FAQ {
    faqId: number;
    faqQuestion: string;
    faqAnswer: string;
    category: string;
    faqCatId: number;
    isActive: boolean;
}

type CategoryData = [string, FAQ[]];

export default function Faqs() {
    const { faqs, error } = useFaqs();
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);

    // Fix the type assertion
    const formattedData = Object.entries(faqs || {}) as unknown as CategoryData[];

    const filteredData = useMemo(() => {
        if (!searchTerm) return formattedData;

        return formattedData
            .map(([category, items]) => [
                category,
                items.filter(item =>
                    item.faqQuestion.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.faqAnswer.toLowerCase().includes(searchTerm.toLowerCase())
                )
            ] as CategoryData)
            .filter(([_, items]) => items.length > 0);
    }, [formattedData, searchTerm]);

    const toggleQuestion = (faqId: number) => {
        setExpandedQuestions(prev => 
            prev.includes(faqId) 
                ? prev.filter(id => id !== faqId)
                : [...prev, faqId]
        );
    };

    if (error) {
        return <p style={{ color: 'red' }}>Error: {error.message}</p>;
    }

    return (
        <DashLayout>
            <div className={styles.container}>
                <h1>Frequently Asked Questions</h1>
                <div className={styles.introSection}>
                    <p className={styles.description}>
                        Find answers to common questions about using the FoodMarble dashboard.<br />
                        Click on any question to view its answer.
                    </p>
                    <div className={styles.descriptionDivider} />
                </div>

                <div className={styles.searchBar}>
                    <div className={styles.inputWrapper}>
                        <span className={styles.iconWrapper}><FiSearch /></span>
                        <input
                            type="text"
                            placeholder="Search FAQs..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>

                {filteredData.length > 0 ? (
                    <div className={styles.faqsGrid}>
                        {filteredData.map(([category, items], index) => (
                            <div key={`category-${category}`} className={styles.categorySection}>
                                <div className={styles.categoryName}>{category}</div>
                                <div className={styles.faqsList}>
                                    {items.map((item, itemIndex) => (
                                        <div 
                                            key={`faq-${item.faqId}`} 
                                            className={styles.faqItem}
                                        >
                                            <button 
                                                className={styles.questionButton}
                                                onClick={() => toggleQuestion(item.faqId)}
                                                aria-expanded={expandedQuestions.includes(item.faqId)}
                                            >
                                                <span>{item.faqQuestion}</span>
                                                {expandedQuestions.includes(item.faqId) 
                                                    ? <FiChevronUp /> 
                                                    : <FiChevronDown />
                                                }
                                            </button>
                                            {expandedQuestions.includes(item.faqId) && (
                                                <div className={styles.answer}>
                                                    <div dangerouslySetInnerHTML={{ __html: item.faqAnswer }} />
                                                </div>
                                            )}
                                            {!expandedQuestions.includes(item.faqId) && itemIndex !== items.length - 1 && (
                                                <div className={styles.questionDivider} />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>Fetching FAQs...</p>
                )}
            </div>
        </DashLayout>
    );
}
