import { DateTime } from "luxon";
import tz from "../tz";
import Gas from "./Gas";
import { Provider } from "./Provider";
import Symptom from "./Symptom";
import { dateFormat } from "../utils";
import { ReferringClinician } from "./ReferringClinician";

export class Test {
  static SIBO_LABELS = ['lactulose', 'glucose'];
  readonly challengeCreatedOnMs: number;
  readonly chalCreatedOn: string;
  readonly testCreatedOn: string;
  readonly testCreatedOnMs: number;
  readonly fid: number;
  readonly label: string;
  readonly amount: number;
  readonly unitsId: number;
  readonly result: number;
  readonly ppms: Partial<{[gasId: number]: number}>;
  readonly referringClinician: ReferringClinician | undefined;
  
  constructor(challengeCreatedOnMs: number, testCreatedOnMs: number, fid: number, label: string, amount: string, unitsId: number, result: number, ppms: Partial<{[gasId: number]: number}>, referringClinician: ReferringClinician|undefined) {
    this.challengeCreatedOnMs = challengeCreatedOnMs;
    this.chalCreatedOn = tz.toLocal(challengeCreatedOnMs).toISO();
    this.testCreatedOn = tz.toLocal(testCreatedOnMs).toISO();
    this.testCreatedOnMs = testCreatedOnMs;
    this.fid = fid;
    this.label = label;
    this.amount = parseFloat(amount);
    this.unitsId = unitsId;
    this.result = result;
    this.fid = fid;
    this.ppms = ppms;
    this.referringClinician = this.createReferringClinician(referringClinician);
  }

  get time():string {
    return DateTime.fromISO(this.testCreatedOn).toFormat("HH:mm");
  }

  get dateTime(): DateTime {
    return DateTime.fromISO(this.testCreatedOn);
  }

  createReferringClinician(referringClinician: any): ReferringClinician|undefined {
    if (!referringClinician) return undefined;
    const [salutation,name,surname,postNominal] = referringClinician;
    return {
      salutation,
      postNominal,
      name,
      surname,
    }
  }

  date(provider: Provider): string {
    return DateTime.fromISO(this.testCreatedOn).toFormat(dateFormat(provider).date);
  }

  ppm(gas: Gas): number|null {
    return (this.ppms ? this.ppms[gas.gasId] : null) ?? null;
  }

  hasGas(gas: Gas): boolean {
    return this.ppms ? gas.gasId in this.ppms : false;
  }

  symptomScoreForTest(symptom: Symptom, nextTest: Test): number|null {
    if (!symptom)
      return null;

    const result = this.isSymptomForTest(symptom,nextTest) ? symptom.score : null;
    return result;
  }

  isSymptomForTest(symptom: Symptom,nextTest: Test): boolean {
    return symptom.pertainsTo >= this.testCreatedOn && (!nextTest || symptom.pertainsTo < nextTest.testCreatedOn);
  } 

  isSIBO(): boolean {
    return Test.SIBO_LABELS.includes(this.label.toLowerCase());
  }
}

export const createTest = ({ challengeCreatedOnMs, testCreatedOnMs, fid, label, amount, unitsId, result, ppms, referringClinician }: any) => {
  return new Test(challengeCreatedOnMs, testCreatedOnMs, fid, label, amount, unitsId, result, ppms, referringClinician);
};
