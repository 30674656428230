import React from 'react';
import Modal from '../modal/Modal';
import styles from './VideoModal.module.scss';

interface VideoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
  title: string;
}

const VideoModal: React.FC<VideoModalProps> = ({ isOpen, onClose, videoUrl, title }) => {
  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onClose}
      width="70svw"
    >
      <div className={styles.videoContainer}>
        <iframe 
          src={videoUrl}
          frameBorder="0" 
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
          allowFullScreen
          className={styles.videoFrame}
          title={title}
        ></iframe>
      </div>
    </Modal>
  );
};

export default VideoModal; 