import React, { Suspense } from "react";
import PreloginLayout from "../../../layouts/prelogin/PreloginLayout";
import { AppError, parseURLSearchParams } from "../../../utils/utils";
import useProvider from "../../../hooks/useProvider";
import useTranslation from "../../../hooks/useTranslation";
import PreLoginError from "../../../components/errors/PreLoginError/PreLoginError";
import s from "./GettingStarted.module.scss";

const HELP_EMAIL = "healthcare@foodmarble.com";
const DEFAULT_VIDEO_LINK = "https://www.youtube.com/watch?v=NOaMGVvCpP0";
const RECIPE_ARTICLES_LINK = "https://foodmarble.com/articles/";
const preChallengeBlogLink = "https://foodmarble.com/articles/pre-challenge-meals/";

const base = getLinkBase();
const LOW_FODMAP_HANDOUT_LINK = `${base}/api/pdfs/Low-FODMAP-Food-List.pdf`;

const GettingStarted = ({ history }) => (
  <Suspense fallback={<PreloginLayout />}>
    <_GettingStarted history={history} />
  </Suspense>
);

const _GettingStarted = ({ history }) => {
  const { t } = useTranslation();
  const { prid } = parseURLSearchParams(history.location.search);
  const { provider, error } = useProvider(prid);

  if (!prid) return <PreLoginError error={new AppError(400, "Bad Request")} />;
  if (!provider) return <PreloginLayout />;
  if (error) return <PreLoginError error={error} />;

  return (
    <PreloginLayout>
      <div className={s.gettingStarted}>
        <h2>{t("gettingStarted.meta.subject")}</h2>
        <p>{t("gettingStarted.intro")}</p>
        
        <h3>{t("gettingStarted.theDayBefore.title")}</h3>
        <ul>
          {t("gettingStarted.theDayBefore.list", { returnObjects: true }).map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
        
        <h3>{t("gettingStarted.theDayOf.title")}</h3>
        <ul>
          {t("gettingStarted.theDayOf.list", { returnObjects: true }).map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
        
        <p>{t("gettingStarted.whatYouWillNeed.text")}</p>
        <ul>
          {t("gettingStarted.whatYouWillNeed.list", { returnObjects: true }).map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        
        <p>{t("gettingStarted.testSteps.text")}</p>
        <ul>
          {t("gettingStarted.testSteps.list", { returnObjects: true }).map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
        
        <p>{t("gettingStarted.whenWillIGetResults.help", { helpEmail: HELP_EMAIL })}</p>
        <p>{t("gettingStarted.farewell.farewell")}</p>
        <p>{t("gettingStarted.farewell.sender")}</p>
      </div>
    </PreloginLayout>
  );
};

export default GettingStarted;

export function getLinkBase() {
  return process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_ENV === "prod"
      ? "https://clinical.foodmarble.com"
      : "https://clinical-test.foodmarble.com"
    : "http://127.0.0.1:7000";
}
