/*
 * Remember that this LoginContext is only for front-end (a.k.a. UI) purposes
 * It cannot / does not provide a solid security
 * ALWAYS VERIFY YOUR TOKEN ON THE BACK-END FOR EACH API REQUEST
 *
 * This context will store/update the following information:
 *  - loginToken: the session token to be used in all verifications (i.e. api requests)
 *  - loginSuccess: the login flag to determine if the visitor should be treated as logged in or logged out
 *  - staff: information about clinician / admin
 *  - provider: information about provider (the organization with which staff is associated)
 */

import { Patient } from "../../utils/data-classes/Patient";
import { Provider } from "../../utils/data-classes/Provider";
import { Staff } from "../../utils/data-classes/Staff";
import foodmarbleLogo from "../../assets/provider-logos/foodmarble.png";
import React, { createContext, useEffect, useReducer } from "react";
import { devLog, invalidateLoginToken, verifyLoginToken } from "../../utils/utils";
import { LoginContextAction, setLoginToken, setLoginSuccess, logIn, setProviderLogo, logOut, abortLogout } from "./LoginContextActions";
import loginContextReducer from "./LoginContextReducer";
import { Support } from "../../utils/data-classes/Support";
import { Preferences } from "../../utils/data-classes/Preferences";

export interface LoginContextState {
  loginToken: string | undefined,
  loginSuccess: boolean | undefined,
  loginDetails: LoginDetails | undefined,
  loggingOut: boolean,
  staff: Staff | undefined,
  provider: Provider | undefined,
  patients: Patient[] | undefined,
  providerLogo: string | undefined,
  support: Support | undefined,
  preferences: Preferences | undefined, 
}

export interface LoginContextType {
  state: LoginContextState,
  dispatch: React.Dispatch<LoginContextAction>
}

export type LoginMethod = "SAML" | "PASSWORD" | "MAGIC LINK";

export interface LoginDetails {
  email: string,
  method: LoginMethod,
}

// Set the initial state for the context
let initialState: LoginContextState = {
  loginToken: undefined,
  loginSuccess: undefined, // initialize as null (not false) to prevent glitchy UI
  loginDetails: undefined,
  loggingOut: false,
  staff: undefined,
  provider: undefined,
  providerLogo: foodmarbleLogo,
  patients: undefined,
  support: undefined,
  preferences: undefined,
};

export const LoginContext = createContext<LoginContextType>({state: initialState, dispatch: () => {}});

export const LoginContextProvider = ({children}: {children: React.ReactNode}) => {
  // Create store and a store updating function using a reducer
  const [state, dispatch] = useReducer<React.Reducer<LoginContextState,LoginContextAction>>(loginContextReducer, initialState);

  // Initial app mount only:
  // Get token from localStorage if exists
  useEffect(() => {
    devLog("LOGIN_CTX: Checking localStorage for session token...");
    
    // Check URL for auth token first
    const params = new URLSearchParams(window.location.search);
    const authToken = params.get('authToken');
    
    if (authToken) {
      devLog("LOGIN_CTX: Found auth token in URL, using for auto-login");
      
      // Store token in localStorage and dispatch to state
      window.localStorage.setItem("login-token", authToken);
      dispatch(setLoginToken(authToken));
      
      // Clean up URL to remove token for security
      const newUrl = window.location.pathname + 
        window.location.search.replace(/[?&]authToken=[^&]+(&|$)/, '$1');
      window.history.replaceState({}, document.title, newUrl);
      
      return; // Skip the localStorage check since we've already set a token
    }
    
    // If no token in URL, check localStorage as usual
    let token = window.localStorage.getItem("login-token");

    // If token exists in localStorage, set token
    if (token) dispatch(setLoginToken(token));
    // If not, set loginSuccess to false to initiate a login screen
    else dispatch(setLoginSuccess(false));
  }, []);

  // On each new token update:
  // Verify token and update loginSuccess
  useEffect(() => {
    if (!state.loginToken) return;
    devLog(`LOGIN_CTX: New token provided. Verifying...`);

    verifyLoginToken(state.loginToken).then(
      ({ success, staff, provider, patients, support, preferences }) => {
        // If token is valid, log staff in and set staff & provider info
        if (success) {
          dispatch(logIn({ staff, provider, patients, support, preferences }));
          devLog("LOGIN_CTX: Verified. Staff signed in.");
        }
        // If token is invalid, remove it from localStorage
        else {
          dispatch(setLoginToken(undefined));
          // Also explicitly set loginSuccess to false to trigger login screen
          dispatch(setLoginSuccess(false));
          devLog("LOGIN_CTX: Token is invalid or expired.");
        }
      }
    ).catch(error => {
      // Handle any errors during verification
      console.error("Token verification error:", error);
      dispatch(setLoginToken(undefined));
      dispatch(setLoginSuccess(false));
      devLog("LOGIN_CTX: Error verifying token.");
    });
  }, [state.loginToken]);

  /**
   * Reset provider logo to FoodMarbles on logout, fetch and set to providers logo on login if provider has a logo
   */
  useEffect(() => {
    if (!state.loginSuccess) {
      dispatch(setProviderLogo(foodmarbleLogo));
      return;
    }
    if (!state.provider || !state.provider.logo) return;
    dispatch(setProviderLogo(undefined));
    state.provider.fetchLogoImage().then(logo => dispatch(setProviderLogo(logo))).catch(err => setProviderLogo(undefined));
  },[state.loginSuccess]);

  useEffect(() => {
    if (!state.loggingOut || !state.loginToken) return;
    devLog(`LOGIN_CTX: Requesting logout`);
    invalidateLoginToken(state.loginToken)
      .then(() => dispatch(logOut()))
      .catch(err => {
        dispatch(abortLogout());
        throw err;
      });
  },[state.loggingOut]);

  return (
    <LoginContext.Provider value={{ state, dispatch }}>
      {children}
    </LoginContext.Provider>
  );
}